import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';

import './sitemap.scss';
// import { Grid } from "reflexbox"

const Sitemap = props => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.patient} className="patient-sitemap">
      <Seo indication={siteIndications.patient} />
      <CommonContainer>
        <ContentBlock>
          <h2>SITEMAP</h2>
          <div className="link-container">
            <ul>
              <li>
                <Link className="link" to={'/patient/'}>
                  Home
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/#overview'}>
                    Overview
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/patient/what-are-nsaids/'}>
                  What are NSAIDs
                </Link>
              </li>
              <li>
                <Link className="link" to={'/patient/about-sprix/'}>
                  About SPRIX®
                </Link>
              </li>
              <ul>
                <li>
                  <Link
                    className="link"
                    to={'/patient/about-sprix#what-is-sprix'}
                  >
                    What is SPRIX®
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/patient/about-sprix#dosing'}>
                    Dosing
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/prescribing-sprix#how-to-use'}>
                    How to Use
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/patient/sprix-direct#request'}>
                  SPRIX Direct
                </Link>
              </li>
              <li>
                <Link className="link" to={'/resources'}>
                  Resources
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/resources#downloads'}>
                    Downloadable Resources
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/resources#video'}>
                    Video
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/patient/get-sprix-now/'}>
                  Get Sprix Now
                </Link>
              </li>
            </ul>
          </div>
        </ContentBlock>
      </CommonContainer>
    </Layout>
  );
};

export default Sitemap;
